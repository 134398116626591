// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from 'react';
import { Segment, Grid, Form, Container, Message, GridColumn, GridRow, Button, List, Header } from 'semantic-ui-react'
import { Link } from "react-router-dom"
import { API } from "aws-amplify";

let UserInteractionState = {
    FILLING_OUT_FORM_STATE: 1,
    CALLING_BACKEND_API: 2,
    BACKEND_SUCCESSFUL_RESPONSE_RECEIVED: 3,
    BACKEND_FAILED_RESPONSE_RECEIVED: 4,
    UNEXPECTED_ERROR: 5
}

export default class CreatePrototypePage extends Component {

    amazonPhoneToolLink = "https://phonetool.amazon.com/users/"
    amazonPhoneToolPhotoLink = "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="
    isengardCentralAccountUrl = "https://isengard.amazon.com/manage-accounts/823578332757"
    replicationTemplateDownloadUrl = "https://drive.corp.amazon.com/documents/marcilio@/public/template.yaml"
    replicationStackname = "quality-portal-source-repo-replication-stack"
    centralAccountRegion = "us-east-1"

    constructor(props) {
        super(props);

        this.state = {
            prototypeName: '',
            customerName: '',
            techLeadLogin: '',
            tpmLogin: '',
            codeRepositoryName: '',
            repoOwnerEmails: '',
            userInteractionState: UserInteractionState.FILLING_OUT_FORM_STATE,
            createPrototypeApiBackendResponse: null,
            suggestRepoName: true,
            suggestRepoOwnerEmails: true,
            blueprintCollections: {},
            loadingCollections: false,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    formatTextFields(value) {
        // can be used to remove invalid and undesired characters
        return value;
    }

    formatRepoName(value) {
        return value.trim().toLowerCase().replace(/[^a-z0-9-]/gi, '-')
    }

    generateRepoNameSuggestion(customerName, prototypeName) {
        customerName = this.formatRepoName(customerName)
        prototypeName = this.formatRepoName(prototypeName)
        const separator = (customerName && prototypeName) ? '-' : ''
        return customerName + separator + prototypeName
    }

    formatLoginName(value) {
        return value.length > 0 ? value.trim().toLowerCase() + "@amazon.com" : "";
    }

    generateEmailSuggestion(techLeadLogin, tpmLogin) {
        techLeadLogin = this.formatLoginName(techLeadLogin) + "\n";
        tpmLogin = this.formatLoginName(tpmLogin);;
        return techLeadLogin + tpmLogin
    }

    handleChange = async (e, { name, value }) => {
        if (name === 'customerName' && this.state.suggestRepoName) {
            const repoName = this.generateRepoNameSuggestion(value, this.state.prototypeName)
            this.setState({
                codeRepositoryName: repoName,
                [name]: this.formatTextFields(value)
            })
        }
        else if (name === 'prototypeName' && this.state.suggestRepoName) {
            const repoName = this.generateRepoNameSuggestion(this.state.customerName, value)
            this.setState({
                codeRepositoryName: repoName,
                [name]: this.formatTextFields(value)
            })
        }
        else if (name === 'codeRepositoryName') {
            this.setState({
                suggestRepoName: false,
                [name]: this.formatRepoName(value)
            })
        }
        else if (name === 'tpmLogin' && this.state.suggestRepoOwnerEmails) {
            const emailList = this.generateEmailSuggestion(this.state.techLeadLogin, value)
            this.setState({
                repoOwnerEmails: emailList,
                [name]: value
            })
        }
        else if (name === 'techLeadLogin' && this.state.suggestRepoOwnerEmails) {
            const emailList = this.generateEmailSuggestion(value, this.state.tpmLogin)
            this.setState({
                repoOwnerEmails: emailList,
                [name]: value
            })
        }
        else if (name === 'repoOwnerEmails') {
            this.setState({
                suggestRepoOwnerEmails: false,
                [name]: value
            })
        }
        else {
            this.setState({
                [name]: value
            })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let repoOwnerEmails = this.state.repoOwnerEmails.split('\n').map(function (item) {
            return item.trim();
        });
        let bodyJson = {
            "prototypeName": this.state.prototypeName,
            "customerName": this.state.customerName,
            "techLeadLogin": this.state.techLeadLogin,
            "tpmLogin": this.state.tpmLogin,
            "codeRepositoryName": this.state.codeRepositoryName,
            "repoOwnerEmails": repoOwnerEmails
        }
        let params = { 'body': bodyJson }
        this.setState({ userInteractionState: UserInteractionState.CALLING_BACKEND_API });
        try {
            await API.post("PrototypeQualityAPI", "/create_prototype", params)
                .then(async response => {
                    this.state.createPrototypeApiBackendResponse = response
                    this.setState({
                        userInteractionState: UserInteractionState.BACKEND_SUCCESSFUL_RESPONSE_RECEIVED,
                        loadingCollections: true
                    });
                    try {
                        const collections = await API.get("PrototypeQualityAPI", "/integrations/collections", {});
                        this.setState({ loadingCollections: false, blueprintCollections: collections })                        
                    } catch (error) {
                        console.error(error)
                    }
                })
                .catch(error => {
                    this.setState({ userInteractionState: UserInteractionState.BACKEND_FAILED_RESPONSE_RECEIVED });
                    console.error(error)
                });
        }
        catch (error) {
            this.setState({ userInteractionState: UserInteractionState.UNEXPECTED_ERROR });
            console.error(error)
        }
    }

    resetForm = () => {
        this.setState({
            userInteractionState: UserInteractionState.FILLING_OUT_FORM_STATE,
            prototypeName: '',
            customerName: '',
            techLeadLogin: '',
            tpmLogin: '',
            codeRepositoryName: '',
            repoOwnerEmails: '',
            createPrototypeApiBackendResponse: null,
            suggestRepoName: true
        })
    }

    renderUnexpectedError() {
        return (
            <Message>
                <Message.Header>Oops, something went wrong :(</Message.Header>
                <Message.List>
                    <Message.Item>Please contact the Quality Assurance Portal support team @ <b>aws-quality-assurance-portal@amazon.com</b></Message.Item>
                </Message.List>
                <Button primary onClick={this.resetForm}>Back</Button>
            </Message>
        )
    }

    renderBlueprintCollections() {
        const { blueprintCollections, loadingCollections } = this.state;

        if (loadingCollections) {
            return <div>Searching for available blueprints...</div>;
        }

        if (Object.keys(blueprintCollections).length === 0) {
            return <div></div>;
        }

        return (
            <Message>
            <Message.Header>Don't Start Coding from Scratch!</Message.Header>
            <p align="left">
                Select the blueprint that most closely matches your prototype.
                Click the blueprint link to download its latest version.
                Unzip the blueprint file inside your repo. 
                Start coding your prototype!
            </p>
              {Object.values(blueprintCollections).map((collection, index) => (
                <Segment key={index} textAlign='left' color='black'>
                  <Header as="h4">{collection.name}</Header>
                  <List bulleted>
                    {collection.blueprints.map((blueprint, blueprintIndex) => (
                      <List.Item
                        key={blueprintIndex}
                        style={{ cursor: 'pointer' }}
                      >
                        <Link to={blueprint.s3_bucket} target="_blank" download>
                        {blueprint.name}
                        </Link> ({blueprint.type}): {blueprint.description}
                      </List.Item>
                    ))}
                  </List>
                </Segment>
              ))}
            </Message>
        )
    }

    renderRepoCreatedOrUpdatedMessage(status) {
        return (
            <div>
                <Container>
                    <Message positive>
                        Congrats! Your repo <b>{this.state.createPrototypeApiBackendResponse['repository_name']}</b> was {status} in the {this.centralAccountRegion} region in the central account (<a target="_blank" href={this.isengardCentralAccountUrl} rel="noopener noreferrer">global-soln-builders+engagements-code-repos@amazon.com</a>).
                    </Message>
                    {this.renderBlueprintCollections()}
                    <Message>
                        <Message.Header>Enable Code Repository Replication</Message.Header>
                        <p align="left">
                            If you plan to host your prototype code in a CodeCommit repository in another account during development
                            (e.g., the Prototyping Hub account for your prototype) you will need to enable code replication. Don't worry, it's easy!
                        </p>
                        <Segment textAlign='left' color='black'>
                            <b>Step #1</b>: The name of the repository in the other account must match the repo name in the central account: <b>{this.state.createPrototypeApiBackendResponse['repository_name']}</b> (any AWS region should be fine).
                            Before moving to the next step, please log to that account and create the repository.
                        </Segment>
                        <Segment textAlign='left' color='black'>
                            <b>Step #2</b>: Use  <Link to="/repo-replication-template.yaml" target="_blank" download>
                                this CloudFormation template</Link> to deploy a stack named <b>{this.replicationStackname}</b> in the account/region you just created your repo in.
                            All repos in that account/region that have a counterpart repo in the central account (identical name) will be automatically replicated.
                            <small><pre>aws cloudformation deploy --template-file [download_folder]/repo-replication-template.yaml <br />
                                --stack-name {this.replicationStackname} <br />
                                --capabilities CAPABILITY_NAMED_IAM
                            </pre></small>
                        </Segment>
                        <Segment textAlign='left' color='black'>
                            <b>Step #3</b>: After the replication stack is deployed, <b>do not push commits to the central account repo directly</b> as it will break the replication mechanism. <b>Always push code to the repo(s) you created in the prototype account</b>.
                        </Segment>
                    </Message>
                    <Button primary onClick={this.resetForm}>Done</Button>
                    <Segment basic>
                        If you have any questions contact the Quality Assurance Portal team at <b>aws-quality-assurance-portal@amazon.com</b>.
                    </Segment>
                    <Message>
                        {' '}
                    </Message>
                </Container>
            </div>
        )
    }

    renderPrototypeCreationBackendResponse() {
        const { userInteractionState } = this.state;
        return (<div>
            {userInteractionState === UserInteractionState.BACKEND_SUCCESSFUL_RESPONSE_RECEIVED &&
                this.state.createPrototypeApiBackendResponse['repo_creation_status'] === 'new-repo-created' &&
                this.renderRepoCreatedOrUpdatedMessage('created')
            }
            {userInteractionState === UserInteractionState.BACKEND_SUCCESSFUL_RESPONSE_RECEIVED &&
                this.state.createPrototypeApiBackendResponse['repo_creation_status'] === 'repo-already-exists' &&
                this.renderRepoCreatedOrUpdatedMessage('updated')
            }
            {userInteractionState === UserInteractionState.BACKEND_FAILED_RESPONSE_RECEIVED && this.renderUnexpectedError()}
        </div>)
    }

    renderForm() {

        const { 
            codeRepositoryName, 
            userInteractionState, 
            repoOwnerEmails 
        } = this.state;

        return (
            <Form>
                <Form.Input fluid
                    label='Customer Name'
                    name='customerName'
                    required={true}
                    placeholder='e.g., Pfizer'
                    onChange={this.handleChange}
                />
                <Form.Input fluid
                    label='Prototype Name'
                    name='prototypeName'
                    placeholder='e.g., Super Search'
                    required={true}
                    onChange={this.handleChange}
                />
                <Form.Input fluid
                    label='Tech Lead Login (without @amazon.com)'
                    name='techLeadLogin'
                    required={true}
                    placeholder='yourlogin'
                    onChange={this.handleChange}
                />
                <Form.Input fluid
                    label='TPM Login (without @amazon.com)'
                    name='tpmLogin'
                    required={false}
                    placeholder='yourlogin'
                    onChange={this.handleChange}
                />
                <Form.Input fluid
                    icon='tags'
                    label='Code Repository Name (naming convention: [customer-name]-[prototype-name])'
                    name='codeRepositoryName'
                    value={codeRepositoryName}
                    required={true}
                    placeholder='e.g., pfizer-super-search'
                    onChange={this.handleChange}
                />
                <Form.TextArea
                    label='Code Repository Owner Emails (one per line; include the @domain!)'
                    name='repoOwnerEmails'
                    value={repoOwnerEmails}
                    required={true}
                    placeholder='yourlogin@amazon.com'
                    onChange={this.handleChange}
                />
                <Form.Group>
                    <Form.Button primary
                        loading={userInteractionState === UserInteractionState.CALLING_BACKEND_API}
                        disabled={userInteractionState === UserInteractionState.CALLING_BACKEND_API}
                        onClick={this.handleSubmit}>
                        Submit
                    </Form.Button>
                    <Form.Button secondary
                        disabled={userInteractionState === UserInteractionState.CALLING_BACKEND_API}
                        onClick={this.resetForm}>
                        Clear
                    </Form.Button>
                </Form.Group>
            </Form>
        )
    }

    renderPrototypeSummary() {
        let techLeadPhoneToolLink = this.amazonPhoneToolLink + this.state.techLeadLogin
        let techLeadPhoneToolPhotoLink = this.amazonPhoneToolPhotoLink + this.state.techLeadLogin
        let tpmPhoneToolLink = this.amazonPhoneToolLink + this.state.tpmLogin
        let tpmPhoneToolPhotoLink = this.amazonPhoneToolPhotoLink + this.state.tpmLogin
        return (
            <Message>
                <Message.Header>Summary</Message.Header>
                <p />
                <Grid columns={2}>
                    <GridRow>
                        {this.state.techLeadLogin !== '' &&
                            <GridColumn textAlign="center">
                                <p>
                                    <img alt="" src={techLeadPhoneToolPhotoLink} />
                                    <br />
                                    <b>Tech Lead</b>
                                    <br />
                                    (<a target="blank" href={techLeadPhoneToolLink}>{this.state.techLeadLogin}</a>)
                                </p>
                            </GridColumn>
                        }
                        {this.state.tpmLogin !== '' &&
                            <GridColumn textAlign="center">
                                <p>
                                    <img alt="" src={tpmPhoneToolPhotoLink} />
                                    <br />
                                    <b>TPM</b>
                                    <br />
                                    (<a target="blank" href={tpmPhoneToolLink}>{this.state.tpmLogin}</a>)
                                </p>
                            </GridColumn>
                        }
                    </GridRow>
                </Grid>
                <Message.List>
                    {this.state.prototypeName !== '' && <Message.Item>Prototype name: <b>{this.state.prototypeName}</b></Message.Item>}
                    {this.state.customerName !== '' && <Message.Item>Customer name: <b>{this.state.customerName}</b></Message.Item>}
                    {this.state.codeRepositoryName !== '' &&
                        <Message.Item>Code repository: <b>{this.state.codeRepositoryName}</b></Message.Item>
                    }
                    {this.state.repoOwnerEmails !== '' && <Message.Item>Code owner e-mails: <b>{this.state.repoOwnerEmails}</b></Message.Item>}
                </Message.List>
            </Message>
        )
    }

    // Components to explore: Progress (for upload progress), Message (for errors)
    renderCreatePrototypeForm() {
        return (
            <Segment>
                <Message
                    attached
                    header='Prototype Repository Details'
                    content='Fill out the form below to create a repository in the central account for your prototype'
                />
                <p></p>
                <Grid columns={2} divided textAlign="left">
                    <Grid.Row>
                        <Grid.Column>
                            {this.renderForm()}
                        </Grid.Column>
                        <Grid.Column>
                            {this.renderPrototypeSummary()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }

    // Components to explore: Progress (for upload progress), Message (for errors)
    render() {
        const { userInteractionState } = this.state;
        return (
            <Container>
                {(userInteractionState === UserInteractionState.FILLING_OUT_FORM_STATE
                    || this.state.userInteractionState === UserInteractionState.CALLING_BACKEND_API)
                    && this.renderCreatePrototypeForm()
                }
                {(userInteractionState === UserInteractionState.BACKEND_SUCCESSFUL_RESPONSE_RECEIVED
                    || this.state.userInteractionState === UserInteractionState.BACKEND_FAILED_RESPONSE_RECEIVED)
                    && this.renderPrototypeCreationBackendResponse()
                }
                {userInteractionState === UserInteractionState.UNEXPECTED_ERROR && this.renderUnexpectedError()}
            </Container>
        )
    }
}
